// it returns the result of the setPosition function
var getStylesAndCollissions = function getStylesAndCollissions(getPosition, triggerRect, tooltipRect, position) {
  var haventMeasuredTooltipYet = !tooltipRect;
  if (haventMeasuredTooltipYet) {
    return [{
      visibility: 'hidden'
    }, {
      bottom: false,
      left: false,
      top: false,
      right: false
    }];
  }
  return getPosition(triggerRect, tooltipRect, position);
};
export default getStylesAndCollissions;