import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useLayoutEffect, useState } from 'react';
function getRect(element) {
  var scrollX = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var scrollY = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  if (element) {
    return element.getBoundingClientRect();
  }
  return {
    bottom: 0,
    height: 0,
    left: 0 + scrollX,
    right: 0,
    top: 0 + scrollY,
    width: 0
  };
}
var useIsomorphicLayoutEffect = typeof window === 'undefined' ? useEffect : useLayoutEffect;
export default function useRect(ref, update) {
  var _useState = useState(getRect()),
    _useState2 = _slicedToArray(_useState, 2),
    rect = _useState2[0],
    setRect = _useState2[1];
  useIsomorphicLayoutEffect(function () {
    var handleScroll = function handleScroll() {
      if (update && ref && ref.current) {
        var _ref$current$getBound = ref.current.getBoundingClientRect(),
          left = _ref$current$getBound.left,
          top = _ref$current$getBound.top,
          width = _ref$current$getBound.width,
          height = _ref$current$getBound.height,
          bottom = _ref$current$getBound.bottom,
          right = _ref$current$getBound.right;
        var scrollX = window.scrollX || document.documentElement.scrollLeft;
        var scrollY = window.scrollY || document.documentElement.scrollTop;
        setRect({
          left: left + scrollX,
          top: top + scrollY,
          width: width,
          height: height,
          bottom: bottom,
          right: right
        });
      }
    };
    handleScroll();
    window.addEventListener('scroll', handleScroll, true);
    return function () {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [ref, update]);
  return rect;
}